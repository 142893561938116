import request from '@/utils/request'

// 查询小程序用户隐私协议
export function findMiniPrivacyAgreement() {
  return request({
    url: `/agreements/mini_privacy`,
    method: 'get'
  })
}

// 查询小程序用户使用协议
export function findMiniUseAgreement() {
  return request({
    url: `/agreements/mini_use`,
    method: 'get'
  })
}

// 查询APP用户隐私协议
export function findAppPrivacyAgreement() {
  return request({
    url: `/app_privacy`,
    method: 'get'
  })
}

// 查询APP用户使用协议
export function findAppUseAgreement() {
  return request({
    url: `/app_use`,
    method: 'get'
  })
}

// 更新小程序用户隐私协议
export function UpdateMiniPrivacyAgreement(data) {
  return request({
    url: `/agreements/mini_privacy`,
    method: 'put',
    data
  })
}

// 更新小程序用户使用协议
export function UpdateMiniUseAgreement(data) {
  return request({
    url: `/agreements/mini_use`,
    method: 'put',
    data
  })
}

// 更新APP用户隐私协议
export function UpdateAppPrivacyAgreement(data) {
  return request({
    url: `/agreements/app_privacy`,
    method: 'put',
    data
  })
}

// 更新APP用户使用协议
export function UpdateAppUseAgreement(data) {
  return request({
    url: `/agreements/app_use`,
    method: 'put',
    data
  })
}

// 查询APP应用
export function findAppUpgrade(data) {
  return request({
    url: `/app_upgrades`,
    method: 'get',
    data
  })
}

// 更新APP应用
export function updateAppUpgrade(data) {
  return request({
    url: `/app_upgrades`,
    method: 'put',
    data
  })
}

