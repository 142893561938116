<template>
  <div>
    <a-form
      class="custom-compact-form"
      :form="form"
      style="width: 1200px;"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 20 }"
      @submit="handleSubmit"
    >

      <a-form-item label="协议名称">
        <a-input
          style="width: 200px"
          v-decorator="['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入协议名称' },
              { max: 20, message: '最多20个字符' },
            ]
          }]"
          autocomplete="off"
        />
      </a-form-item>

      <a-form-item label="版本号">
        <a-input
          style="width: 200px"
          v-decorator="['version', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入版本号' },
              { max: 20, message: '最多20个字符' },
            ]
          }]"
          autocomplete="off"
        />
      </a-form-item>

      <a-form-item>
        <wang-editor-frequently-asked-question
          v-decorator="['content']"
        />
      </a-form-item>
      <a-form-item class="set-button-center">
        <a-button type="primary" html-type="submit" :loading="submitting">
          保存
        </a-button>
      </a-form-item>

    </a-form>

  </div>
</template>

<script>
import WangEditorFrequentlyAskedQuestion from '@/components/Editor/WangEditor'
import { findAppUseAgreement, UpdateAppUseAgreement } from '@/api/agreement'

export default {
  name: 'IndexAppUseAgreement',
  components: {
    WangEditorFrequentlyAskedQuestion
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'app_use_agreement_edit' }),
      submitting: false
    }
  },
  created() {
    this.initEditor()
  },
  methods: {
    initEditor() {
      // 访问编辑框的时候加载以前保存的内容
      findAppUseAgreement().then((res) => {
        this.form.setFieldsValue({
          name: res.data.name,
          version: res.data.version,
          content: res.data.content
        })
      })
    },

    // 保存事件
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          UpdateAppUseAgreement(values).then((res) => {
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.set-button-center {
  text-align: center;
}
</style>
